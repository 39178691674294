import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 345px;
  height: 457px;
  position: relative;
`;

export const LogoBackOffice = styled.img`
  width: 345px;
  height: 457px;
  position: relative;
`;

export const ButtonSubmit = styled.button`
  background: #2f80ed;
  border-radius: 0.875rem;
  width: 79px;
  height: 29px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px auto 20px auto;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 109%;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  img {
    margin-left: 0.25rem;
  }

  &:disabled {
    background: #b0b0b0;
  }
`;

export const ButtonLink = styled.div`
  display: flex;
  button {
    background-color: transparent;
    font-weight: 400;
    font-size: 0.625rem;
    color: #2e2b37;
    text-decoration: none;
    margin: auto;
    display: flex;
  }
`;
